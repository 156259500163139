.redirect-background {
    background-color: #E5E5E5;
}

.redirect-imanage-box {
    background-color: #fff;
    border: 1px solid rgb(237, 231, 231);
    width: 400px;
    height: 400px;
    padding: 20px;

}